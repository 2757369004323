<template>
  <div>
    这是一个404页面
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
  created() {},
  computed: {},
  methods: {},
}
</script>
<style lang="less" scoped></style>
